<script>
import {LMap, LTileLayer, LMarker, LTooltip, LGeoJson} from 'vue2-leaflet';
import { Icon, icon } from 'leaflet';
import axios from "axios";
import { Auth } from 'aws-amplify';
import HIGH_RISK_AREAS_QUERY from '@graphql/high-risk-areas/queries/high-risk-areas.gql';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: '/img/fleet-tracking/car-marker-green.svg',
  iconUrl: '/img/fleet-tracking/car-marker-green.svg',
  shadowUrl: '',
  iconSize:[37, 42],

});

export default {
  name: 'FleetPositionMap',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LGeoJson
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    tracking: {
      type: Array,
      require: true,
      default: []
    },
    updateTracking: {
      type: Object,
      require: true,
      default: () => {}
    },
    fleet: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data () {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; OpenStreetMap',
      zoom: 16,
      center: [ this.tracking[0].position.location[0],  this.tracking[0].position.location[1]],
      markerLatLng: [ this.tracking[0].position.location[0],  this.tracking[0].position.location[1]],
      teste: [ -23.6374362,-46.5442045],
      carBattery:this.tracking[0]?.battery?.car || 0,
      deviceBattery: this.tracking[0]?.battery?.sensor || 0,
      garageIcon: icon({
        iconUrl: '/img/fleet-tracking/car-workshop.png',
      }),
      garages: [],
      highRiskAreas: []
    };
  },
  mounted() {
    this.getGarages();
  },
  methods: {
    async getGarages() {
      const session =  await Auth.currentSession();
      const authToken = await session.getIdToken().getJwtToken();

      const listGarages = await axios({
        method: 'GET',
        url: process.env.KOVI_API_URL+'/utils/garages/safety',
        crossDomain: true,
        headers: {
          Authorization: "Bearer " + authToken,
          'content-type': 'application/json',
        },
      });

      if (listGarages.data) {
        listGarages.data.items.map((item) => {
          if( item?.address_latlong?.coordinates) {
            this.garages.push(item)
          }
        })
      }
    }
  },
  apollo: {
    highRiskAreas: {
      query: HIGH_RISK_AREAS_QUERY,
    }
  }
}

</script>
<template>
  <l-map class="google-map" :zoom="zoom" :center="center">
    <l-tile-layer :url="url" :attribution="attribution" />
    <l-marker
      v-for="(garage, index) in garages"
      :key="index"
      :lat-lng="garages[index].address_latlong.coordinates || markerLatLng"
      :icon="garageIcon"
    >
      <l-tooltip>
        <p>
          <b>Oficina: </b>{{ garage.name }}
          <br>
          <b>Endereço: </b>{{ garage.address_street_name }} {{ garage.address_street_number }}, <br>
          {{ garage.address_neighborhood }} - {{ garage.address_city }}
        </p>
      </l-tooltip>
    </l-marker>

    <l-marker :lat-lng="markerLatLng">
      <l-tooltip>
        <div>
          <p>
            <i class="fa fa-battery-three-quarters" />
            {{ $i18n.t('fleet.texts.batteryVoltage') }}: <strong>{{ carBattery || $i18n.t('fleet.texts.noInfoFound') }} V</strong>
            <br>
            <i class="fa fa-bolt" aria-hidden="true" />
            {{ $i18n.t('fleet.texts.trackerVoltage') }}: <strong>{{ deviceBattery || $i18n.t('fleet.texts.noInfoFound') }} V</strong>
          </p>
        </div>
      </l-tooltip>
    </l-marker>

    <l-geo-json
      v-for="(riskArea) in highRiskAreas"
      :key="riskArea.id"
      :geojson="riskArea.geojson"
      :options-style="{ fillColor: 'red', color: 'red' }"
    />
  </l-map>
</template>
<style scoped>
.map-title {
  font-size: 18px!important;
  font-weight: bold!important;
}
.google-map {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: gray;
}
</style>
