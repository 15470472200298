var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "l-map",
    {
      staticClass: "google-map",
      attrs: { zoom: _vm.zoom, center: _vm.center },
    },
    [
      _c("l-tile-layer", {
        attrs: { url: _vm.url, attribution: _vm.attribution },
      }),
      _vm._l(_vm.garages, function (garage, index) {
        return _c(
          "l-marker",
          {
            key: index,
            attrs: {
              "lat-lng":
                _vm.garages[index].address_latlong.coordinates ||
                _vm.markerLatLng,
              icon: _vm.garageIcon,
            },
          },
          [
            _c("l-tooltip", [
              _c("p", [
                _c("b", [_vm._v("Oficina: ")]),
                _vm._v(_vm._s(garage.name) + " "),
                _c("br"),
                _c("b", [_vm._v("Endereço: ")]),
                _vm._v(
                  _vm._s(garage.address_street_name) +
                    " " +
                    _vm._s(garage.address_street_number) +
                    ", "
                ),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(garage.address_neighborhood) +
                    " - " +
                    _vm._s(garage.address_city) +
                    " "
                ),
              ]),
            ]),
          ],
          1
        )
      }),
      _c(
        "l-marker",
        { attrs: { "lat-lng": _vm.markerLatLng } },
        [
          _c("l-tooltip", [
            _c("div", [
              _c("p", [
                _c("i", { staticClass: "fa fa-battery-three-quarters" }),
                _vm._v(
                  " " + _vm._s(_vm.$i18n.t("fleet.texts.batteryVoltage")) + ": "
                ),
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.carBattery || _vm.$i18n.t("fleet.texts.noInfoFound")
                    ) + " V"
                  ),
                ]),
                _c("br"),
                _c("i", {
                  staticClass: "fa fa-bolt",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(
                  " " + _vm._s(_vm.$i18n.t("fleet.texts.trackerVoltage")) + ": "
                ),
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.deviceBattery ||
                        _vm.$i18n.t("fleet.texts.noInfoFound")
                    ) + " V"
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._l(_vm.highRiskAreas, function (riskArea) {
        return _c("l-geo-json", {
          key: riskArea.id,
          attrs: {
            geojson: riskArea.geojson,
            "options-style": { fillColor: "red", color: "red" },
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }