var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c("fleet-header", { attrs: { id: _vm.$route.params.id } }),
      !_vm.visitReason
        ? _c(
            "div",
            [_c("visit-reason", { on: { ok: _vm.handleVisitReason } })],
            1
          )
        : _c("div", [
            !_vm.$apollo.loading
              ? _c("div", [
                  _vm.trackingVin
                    ? _c(
                        "div",
                        [
                          _c(
                            "b-card-group",
                            { staticClass: "driver-data mb-4" },
                            [
                              _vm.mapLoad
                                ? _c(
                                    "b-card",
                                    {
                                      staticStyle: { "min-height": "300px" },
                                      attrs: { "body-class": "p-0" },
                                    },
                                    [
                                      _c("fleet-position-map", {
                                        attrs: {
                                          name: "car-location",
                                          tracking: [_vm.trackerData],
                                          fleet: true,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.mapLoad
                                ? _c(
                                    "b-card",
                                    { attrs: { "body-class": "p-0" } },
                                    [
                                      _c("b-link", {
                                        staticClass: "street-view-image",
                                        style: {
                                          backgroundSize: "cover",
                                          display: "block",
                                          width: "100%",
                                          height: "100%",
                                          backgroundImage: `url(${_vm.googleStaticMap(
                                            _vm.trackerData.position
                                          )})`,
                                        },
                                        attrs: {
                                          title: "Abrir em nova janela",
                                          href:
                                            "https://www.google.com/maps/search/?api=1&query=" +
                                            _vm.trackerData.position
                                              .location[0] +
                                            "," +
                                            _vm.trackerData.position
                                              .location[1],
                                          target: "_blank",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-card-group",
                            [
                              _c("b-card", [
                                _c("h3", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$i18n.t("fleet.titles.carInfo")
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("p", [
                                  _c("i", {
                                    staticClass: "fa fa-road",
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$i18n.t("fleet.texts.speed")) +
                                      ": "
                                  ),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.trackerDataApi.speed || 0) +
                                        "Km/h"
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("i", {
                                    staticClass: "fa fa-power-off",
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$i18n.t("fleet.texts.ignition")
                                      ) +
                                      ": "
                                  ),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.trackerDataApi.engine
                                          ? _vm.$i18n.t("fleet.texts.on")
                                          : _vm.$i18n.t("fleet.texts.off")
                                      )
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("i", {
                                    staticClass: "fa fa-refresh",
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$i18n.t("fleet.texts.lastEvent")
                                      ) +
                                      ": "
                                  ),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getLastEvent(
                                          _vm.trackerDataApi.behavior
                                        ) ||
                                          _vm.$i18n.t("fleet.texts.noInfoFound")
                                      )
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("i", {
                                    staticClass: "fa fa-battery-three-quarters",
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$i18n.t(
                                          "fleet.texts.batteryVoltage"
                                        )
                                      ) +
                                      ": "
                                  ),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.battery.vehicleBattery ||
                                          _vm.$i18n.t("fleet.texts.noInfoFound")
                                      ) + " V"
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("i", {
                                    staticClass: "fa fa-barcode",
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$i18n.t("fleet.texts.vin")) +
                                      ": "
                                  ),
                                  _c("strong", [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "texts font-weight-bold",
                                        attrs: { id: "clip-vin" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.trackingVin ||
                                                _vm.$i18n.t(
                                                  "fleet.texts.noInfoFound"
                                                )
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              variant: "link",
                                              size: "sm",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$copyText(
                                                  "clip-vin",
                                                  _vm.trackingVin
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-clone",
                                              attrs: { "aria-hidden": "true" },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c(
                                "b-card",
                                [
                                  _c("h3", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$i18n.t("fleet.titles.trackerInfo")
                                      )
                                    ),
                                  ]),
                                  _c("tracker-info", {
                                    attrs: {
                                      "tracker-data": _vm.trackerDataApi,
                                      mode: "tracker",
                                    },
                                  }),
                                  _c("p", [
                                    _c("i", {
                                      staticClass: "fa fa-barcode",
                                      attrs: { "aria-hidden": "true" },
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$i18n.t(
                                            "fleet.texts.trackerSerialNumber"
                                          )
                                        ) +
                                        ": "
                                    ),
                                    _c("strong", [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "texts font-weight-bold",
                                          attrs: { id: "clip-device-id" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.trackerDataApi.deviceId ||
                                                  _vm.$i18n.t(
                                                    "fleet.texts.noInfoFound"
                                                  )
                                              ) +
                                              " "
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                variant: "link",
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$copyText(
                                                    "clip-device-id",
                                                    _vm.trackerDataApi.deviceId
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-clone",
                                                attrs: {
                                                  "aria-hidden": "true",
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-card-group",
                            [
                              _c("b-card", [
                                _c("h3", [
                                  _vm._v(
                                    _vm._s(_vm.$i18n.t("fleet.titles.tracking"))
                                  ),
                                ]),
                                _c("p", [
                                  _c("i", {
                                    staticClass: "fa fa-tachometer",
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$i18n.t("fleet.texts.vehicleMoving")
                                      ) +
                                      ": "
                                  ),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        !_vm.trackerDataApi.flags.vehicleMoving
                                          ? _vm.$i18n.t("fleet.texts.no")
                                          : _vm.$i18n.t("fleet.texts.yes")
                                      )
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("i", {
                                    staticClass: "fa fa-map-marker",
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$i18n.t("fleet.texts.vehicleAtKovi")
                                      ) +
                                      ": "
                                  ),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        !_vm.trackerDataApi.flags.vehicleAtKovi
                                          ? _vm.$i18n.t("fleet.texts.no")
                                          : _vm.$i18n.t("fleet.texts.yes")
                                      )
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("i", {
                                    staticClass: "fa fa-microchip",
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$i18n.t(
                                          "fleet.texts.jammerDetected"
                                        )
                                      ) +
                                      ": "
                                  ),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        !_vm.trackerDataApi.flags.jammerDetected
                                          ? _vm.$i18n.t("fleet.texts.no")
                                          : _vm.$i18n.t("fleet.texts.yes")
                                      )
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("i", {
                                    staticClass: "fa fa-exclamation-triangle",
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$i18n.t("fleet.texts.vehicleZombie")
                                      ) +
                                      ": "
                                  ),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        !_vm.trackerDataApi.flags.vehicleZombie
                                          ? _vm.$i18n.t("fleet.texts.no")
                                          : _vm.$i18n.t("fleet.texts.yes")
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c(
                                "b-card",
                                [
                                  _c("h3", [
                                    _vm._v(
                                      _vm._s(_vm.$i18n.t("fleet.titles.blocks"))
                                    ),
                                  ]),
                                  _c("blocks-info", {
                                    attrs: {
                                      "tracker-data": _vm.trackerDataApi,
                                      mode: "tracker",
                                    },
                                  }),
                                  !_vm.isRental
                                    ? _c("div", [
                                        _vm.trackerDataApi.flags
                                          .tokenActivated &&
                                        !_vm.trackerDataApi.engine
                                          ? _c(
                                              "p",
                                              { staticClass: "text-success" },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-check-circle",
                                                  attrs: {
                                                    "aria-hidden": "true",
                                                  },
                                                }),
                                                _c("strong", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$i18n.t(
                                                          "fleet.texts.virtualTokenAvailable"
                                                        )
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _c(
                                              "p",
                                              { staticClass: "text-danger" },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-times-circle",
                                                  attrs: {
                                                    "aria-hidden": "true",
                                                  },
                                                }),
                                                _c("strong", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$i18n.t(
                                                          "fleet.texts.virtualTokenUnvailable"
                                                        )
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                      ])
                                    : _vm._e(),
                                  _c("p", [_c("strong")]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "b-alert",
                            { attrs: { variant: "danger", show: true } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$i18n.t("fleet.texts.alertNoInfo")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                ])
              : _c(
                  "div",
                  { staticClass: "animated fadeIn" },
                  [_c("content-loading")],
                  1
                ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }