var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "visitReasonModal",
        size: "md",
        centered: "",
        title: _vm.$t(`fleet.tracking.visitReason.label`),
        "ok-title": _vm.$t(`fleet.tracking.visitReason.save`),
        "cancel-title": _vm.$t(`fleet.tracking.visitReason.cancel`),
        "ok-disabled": _vm.isLoading || !_vm.visitReason,
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "hide-header-close": "",
      },
      on: { ok: _vm.handleSubmit, cancel: _vm.handleCancel },
    },
    [
      _c("b-form-select", {
        attrs: { options: _vm.options },
        model: {
          value: _vm.visitReason,
          callback: function ($$v) {
            _vm.visitReason = $$v
          },
          expression: "visitReason",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }