<template>
  <b-modal
    id="visitReasonModal"
    size="md"
    centered
    :title="$t(`fleet.tracking.visitReason.label`)"
    :ok-title="$t(`fleet.tracking.visitReason.save`)"
    :cancel-title="$t(`fleet.tracking.visitReason.cancel`)"
    :ok-disabled="isLoading || !visitReason"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <b-form-select v-model="visitReason" :options="options" />
  </b-modal>
</template>

<script>
export default {
  data() {
    const reasons = [
          "tracker_status",
          "vehicle_collection",
          "vehicle_failure",
          "vehicle_location_confirmation",
          "tracker_installation",
          "workshop",
        ].map(reason => ({ value: reason, text: this.$t(`fleet.tracking.visitReason.options.${reason}`) }))

        return {
      analytics: null,
      isLoading: false,
      reasonSelected: false,
      visitReason: null,
      options: [
        { value: null, text: this.$t(`fleet.tracking.visitReason.options.default`) },
        ...reasons,
      ]
    }
  },
  mounted() {
    if (!this.reasonSelected) {
      this.$bvModal.show('visitReasonModal')
    }
  },
  methods: {
    async handleSubmit() {
      const data = {
        reason: this.visitReason,
        email: this.$store.getters['user/attributes'].email,
        plate: this.$store.getters['fleet/header'].license_plate
      }
      await window.analytics.track('TRACKING_VISIT_REASON', data)
      this.$emit('ok', this.visitReason)
    },
    handleCancel() {
      this.$router.push(`/fleet/${this.$store.getters['fleet/header'].id}`);
    }
  }
}
</script>