<template>
  <div>
    <p>
      <i class="fa fa-signal" aria-hidden="true" />
      {{ $i18n.t('fleet.texts.provider') }}: <strong>{{ trackerData.provider || $i18n.t('fleet.texts.noInfoFound') }}</strong>
    </p>
    <p>
      <i class="fa fa-code-fork" aria-hidden="true" />
      {{ $i18n.t('fleet.texts.version') }}: <strong> {{ trackerData.version || $i18n.t('fleet.texts.noInfoFound') }} </strong>
    </p>
    <p>
      <i class="fa fa-rss" aria-hidden="true" />
      {{ $i18n.t('fleet.texts.gps') }}: <strong>{{ getSatellites(trackerData.satellites) || $i18n.t('fleet.texts.noInfoFound') }} </strong>
    </p>
    <p>
      <i class="fa fa-wifi" aria-hidden="true" />
      {{ $i18n.t('fleet.texts.rssi') }}: <strong>{{ getGsmSignal(trackerData.gsmSignal) || $i18n.t('fleet.texts.noInfoFound') }}</strong>
    </p>
    <p>
      <i class="fa fa-upload" />
      {{ $i18n.t('fleet.texts.lastUpdate') }}: <strong>{{ trackerData.lastEventAt | moment('DD/MM/YYYY HH:mm:ss') }}</strong>
    </p>
    <p>
      <i class="fa fa-bolt" aria-hidden="true" />
      {{ $i18n.t('fleet.texts.trackerVoltage') }}: <strong>{{ trackerData.battery.deviceBattery || $i18n.t('fleet.texts.noInfoFound') }} V</strong>
    </p>
  </div>
</template>


<script>
export default {
  name: 'TrackerInfo',
  props: {
    trackerData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {},
  methods: {
    getSatellites(satellitesAmount) {
      if (satellitesAmount >= 7) return this.$i18n.t('fleet.texts.great');
      if (satellitesAmount >= 5) return this.$i18n.t('fleet.texts.good');
      if (satellitesAmount >= 3) return this.$i18n.t('fleet.texts.bad');
      if (satellitesAmount < 3) return this.$i18n.t('fleet.texts.terrible');
    },
    getGsmSignal(gsmSignalValue) {
      if (gsmSignalValue >= -55) return this.$i18n.t('fleet.texts.great');
      if (gsmSignalValue >= -75) return this.$i18n.t('fleet.texts.good');
      if (gsmSignalValue >= -85) return this.$i18n.t('fleet.texts.bad');
      if (gsmSignalValue >= -96) return this.$i18n.t('fleet.texts.terrible');
      return this.$i18n.t('fleet.texts.noSignal');
    },
  },
};
</script>
