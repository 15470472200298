var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("p", [
      _c("i", {
        staticClass: "fa fa-signal",
        attrs: { "aria-hidden": "true" },
      }),
      _vm._v(" " + _vm._s(_vm.$i18n.t("fleet.texts.provider")) + ": "),
      _c("strong", [
        _vm._v(
          _vm._s(
            _vm.trackerData.provider || _vm.$i18n.t("fleet.texts.noInfoFound")
          )
        ),
      ]),
    ]),
    _c("p", [
      _c("i", {
        staticClass: "fa fa-code-fork",
        attrs: { "aria-hidden": "true" },
      }),
      _vm._v(" " + _vm._s(_vm.$i18n.t("fleet.texts.version")) + ": "),
      _c("strong", [
        _vm._v(
          " " +
            _vm._s(
              _vm.trackerData.version || _vm.$i18n.t("fleet.texts.noInfoFound")
            ) +
            " "
        ),
      ]),
    ]),
    _c("p", [
      _c("i", { staticClass: "fa fa-rss", attrs: { "aria-hidden": "true" } }),
      _vm._v(" " + _vm._s(_vm.$i18n.t("fleet.texts.gps")) + ": "),
      _c("strong", [
        _vm._v(
          _vm._s(
            _vm.getSatellites(_vm.trackerData.satellites) ||
              _vm.$i18n.t("fleet.texts.noInfoFound")
          ) + " "
        ),
      ]),
    ]),
    _c("p", [
      _c("i", { staticClass: "fa fa-wifi", attrs: { "aria-hidden": "true" } }),
      _vm._v(" " + _vm._s(_vm.$i18n.t("fleet.texts.rssi")) + ": "),
      _c("strong", [
        _vm._v(
          _vm._s(
            _vm.getGsmSignal(_vm.trackerData.gsmSignal) ||
              _vm.$i18n.t("fleet.texts.noInfoFound")
          )
        ),
      ]),
    ]),
    _c("p", [
      _c("i", { staticClass: "fa fa-upload" }),
      _vm._v(" " + _vm._s(_vm.$i18n.t("fleet.texts.lastUpdate")) + ": "),
      _c("strong", [
        _vm._v(
          _vm._s(
            _vm._f("moment")(_vm.trackerData.lastEventAt, "DD/MM/YYYY HH:mm:ss")
          )
        ),
      ]),
    ]),
    _c("p", [
      _c("i", { staticClass: "fa fa-bolt", attrs: { "aria-hidden": "true" } }),
      _vm._v(" " + _vm._s(_vm.$i18n.t("fleet.texts.trackerVoltage")) + ": "),
      _c("strong", [
        _vm._v(
          _vm._s(
            _vm.trackerData.battery.deviceBattery ||
              _vm.$i18n.t("fleet.texts.noInfoFound")
          ) + " V"
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }